import * as cookies from 'browser-cookies'

export { getCcLoginData, shouldDisplay, clearCookie};


const ccLoginCookieName = 'cclogin-data';

function getCcLoginData() {
    return JSON.parse(atob(cookies.get(ccLoginCookieName)));
}

function shouldDisplay() {
    if (cookies.get(ccLoginCookieName)) {
        return true;
    }

    // cookie empty, null or undefined
    return false;
}

function clearCookie() {
    document.cookie = "cclogin-data=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}
