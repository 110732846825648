import {clearCookie, getCcLoginData, shouldDisplay} from "./cclogin-data";

class CcloginBanner extends HTMLElement {

    constructor() {
        super();
    }

    connectedCallback() {
        // Banner anzeigen, wenn Cookie da ist
        if (shouldDisplay()) {
            this.querySelector('b').innerText = CcloginBanner.text();
            this.classList.add('visible');

            // Cookie bei Logout leeren
            window.addEventListener("kim.logout", () => {
                clearCookie();
            });
        }
    }

    static text() {
        let data = getCcLoginData();
        let text = `Achtung ${data.ccLoginBenutzer}: Sie sind als Kunde ${data.kundenEmail} (Kdnr. ${data.kundennummer} / SAPKnr. ${data.sapKundennummer}) angemeldet!`;
        if (data.fehlversucheGesperrt) {
            text += ' Achtung Sperre: Fehlversuche'
        }
        if (data.fraudGesperrt) {
            text += ' Achtung Sperre: Fraudverdacht'
        }
        return text;
    }

    disconnectedCallback() {}
}

customElements.define('cclogin-banner', CcloginBanner);
